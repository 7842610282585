.card {
  height: 13rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0rem;
  align-items: center;
  width: 11rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.26);
  border: 7px solid var(--orangeCard);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
  padding: 0px 26px 2rem 26px;
}


.card span:nth-of-type(2) {
  color: var(--gray);
  font-size: 16px;
}
.card > img {
  transform: scale(0.5);
  width:11rem;
  height: auto;
  margin-bottom: -2rem;
  margin-top: -1rem;
}
/* cards */




@media screen and (max-width: 480px){
  .card{
    width: auto;
  }
}