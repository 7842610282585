.floatingDiv {
  justify-content: space-around;
  background: white;
  box-shadow: var(--boxShadow);
  border-radius: 17px;
  display: flex;
  align-items: center;
  padding: 0px 28px 0px 0px;
  /* font-weight: 100; */
  height: 4.5rem;
}
.floatingDiv > img {
  transform: scale(0.4);
}
.floatingDiv > span {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 200;
  /* yahan ye color add kia hy bad mein dark mode k waqt */
  color: black;
}

