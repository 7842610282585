.t-wrapper{
    /* padding: 0.5rem 3.5rem; */
    padding:  0 3rem 0 3rem;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}
.t-heading{
    align-self: start;
}
.t-heading>*{
    font-size: 2rem;
    font-weight: bold;
}
.t-heading span:nth-of-type(2){
    color: var(--orange);
}

.t-blur1{
    left: 38rem;
    top: 16rem;
}
.t-blur2{
    top: 9rem;
    left: 0;
}
.testimonial{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 3rem;
    width: 30rem;
    height: 14rem;
    /* styles same as work.css */
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--blueCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
}
.testimonial>img{
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
}
.testimonial>span{
    color: var(--gray);
    transform: scale(0.7);
}


/* slider */
.t-wrapper .swiper {
    width: 100%;
    height: 70%;
}

.t-wrapper .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
}

.t-wrapper .swiper-pagination-bullet-active{
    background: var(--orange);
}

@media screen and (max-width: 800px){

    .t-wrapper{
        padding: 0;
        transform: scale(0.8);
    }

    .t-wrapper .swiper {
        width: 100%;
        height: 90%;
    }

    .testimonial{
        transform: scale(0.8);
        display: flex;
        flex-direction: column;
        align-items: center;
        /* gap: -0.5rem; */
        /* padding: 0rem; */
        padding: 1.5rem 0rem;
        width: 30rem;
        height: 28rem;
        justify-content: space-evenly;
        /* position: relative; */
    }
    
    .testimonial>img{
        /* position: absolute;
        top: 10px;
        left: 130px;
        width: 70px;
        height: 70px; */
    }
    .testimonial > span {
        /* position: absolute;
        top: 55px; */
    }
  }