/* .n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem; // delete this
  width: 100%;
  z-index: 10;
  margin-bottom: 2rem;
  top: 0;
} */

.n-wrapper {
  background-color: aliceblue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--blueCard);
  /* padding-left: 1rem; */
  /* padding: 0.5rem 3.5rem; */
  /* padding-right: 5rem; */
  /* padding: 1rem; */
  position: fixed;
  width: 100%;
  height: 8vh;
  z-index:10;
}


.n-bg {
  background-color: rgb(0,0,0,0.2);
  transition: 3s;
}

.n-left {
  flex: 1;
  align-items: center;
  justify-content: left;
  display: flex;
  /* gap: 2rem; */
}

.n-name {
  font-size: 1.3rem;
  font-weight: bold;
  transform: scale(0.6);
}

.n-name :hover{
  cursor: pointer;
}

/* .n-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
} */

.n-right {
  margin-right: 3rem;
  display: flex;
  /* margin-right: 3rem; */
}

.n-list {
  flex: 10;
}
.n-list > ul {
  display: flex;
  gap: 2rem;
  margin-right: 3rem;
}

.n-list > ul :hover {
  color: var(--orange);
  cursor: pointer;
}

.n-button {
  flex: 2;
}


.hamburger {
  display: none;
}

@media screen and (max-width: 800px) {
  .n-wrapper {
    height: 9vh;
  }
  
  .n-wrapper img {
    transform: scale(0.9);
  }
  .n-list {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 110%;
    height: 200vh;
    background-color: rgb(0,0,0,0.7);
    /* background-color: rgb(0,0,0,0.3); */
    position: absolute;
    top:0;
    left: -150%;
    z-index: -3;
    transition: 0.5s;
  }

  .n-ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .n-ul > li {
    padding-left: 2.5rem;
    padding-top: 2rem;
  }
  .n-ul > li > * {
    color: aliceblue;
    font-size: 1.2rem;
  }

  .n-list > * {
    padding: 1rem 0;
  }

  .active {
    top:-2%;
    left: -1%;
  }

  .hamburger {
    display: initial;
  }
}

.logo-img {
  width: 2px;
}

@media screen and (max-width: 600px) {
  .link-component {
    position: relative;
  }

  .link-component > * {
    position: absolute;
    top: -2rem;
    left: -1.5rem;
  }

  .n-list > * {
    padding: 0.8rem 0;
  }

  .n-ul > li > * {
    font-size: 1rem;
  }
  /* .n-name {
    position: relative;
  }
  
  .n-name img {
    position: absolute;
  } */
}