.footer {
  display: flex;
  align-items: center;
  margin: -0.5rem -3.5rem;
  position: relative;
  margin-top: -7rem;
}
.f-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 4rem;
  gap: 4rem;
  color: white;
}
.f-icons {
  display: flex;
  gap: 2rem;
}

@media screen and (max-width: 800px){
  .footer {
    margin-top: -10rem;
  }
  .f-content{
    transform: scale(0.8);
    gap:1.5rem;
  }

  .f-icons{
    gap: 1rem;
    transform: scale(0.7);
  }
}
