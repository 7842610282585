.services {
  /* padding: 0 3rem 0 3rem; */
  padding: 0.5rem 4.2rem;
  display: flex;
  height: 30rem;
  margin-bottom: 8rem;
  margin-top: 9rem;
  margin-bottom: 13rem;
  /* scroll view */
  padding-top: 3rem;
}
/* left side */
.awesome {
  display: flex;
  flex-direction: column;
  position: relative;
  transform: scale(0.95);
  /* gap: 20px; */
}

.awesome > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.awesome > :nth-child(3) {
  color: var(--gray);
  font-size: 24px;
  margin-top: 1rem;
}
.s-button {
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
}
/* right sided */
.cards {
  position: relative;
  transform: scale(0.9);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cards > * {
  position: absolute;
}

/* blur */
.s-blur2 {
  left: 14rem;
  top: 8rem;
}

.s-blur1 {
  top: 13rem;
  left: -18rem;
}

@media screen and (max-width: 800px) {
  .services {
    transform: scale(0.85);
    padding: 1rem 1rem;
    margin-top: 4rem;
    margin-bottom: 8rem;
    flex-direction: column;
    gap: 0rem;
    height: 110rem;
    /* padding: 0; */
  }

  .cards {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    align-items: flex-start;
    gap: 17rem;
  }
  .cards > * {
    position: static;
  }
}
